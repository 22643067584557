import './Polyfills';
import './i18n/i18n';
import { init, Replay } from '@sentry/react';
import { config, isNative } from './config';

const { REACT_APP_ENV } = process.env;
if (REACT_APP_ENV) {
  init({
    dsn: config.sentry.dsn,
    environment: `${REACT_APP_ENV}${isNative ? '-native' : ''}`,
    ignoreErrors: [
      'ResizeObserver loop',
      "Unexpected token '<'",
      'ChunkLoadError',
      'Loading chunk',
      'CSS_CHUNK_LOAD_FAILED',
      '[Cloudflare Turnstile]',
      'Load failed',
      'Loading CSS chunk',
      'UnhandledRejection: Non-Error promise rejection',
      'Error: Failed to fetch', // Maybe temporarily?
      'TypeError: Failed to fetch', // Maybe temporarily?
      'TypeError: Load failed',
      'TypeError: cancelled',
      'TypeError: The network connection was lost.',
      // Native App
      'plugin is not implemented on android',
      // Ignore list based off: https://gist.github.com/1878283
      // Will cause a deprecation warning, but the demise of `ignoreErrors` is still under discussion.
      // See: https://github.com/getsentry/raven-js/issues/73
      // Random plugins/extensions
      'top.GLOBALS',
      // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      // Facebook borked
      'fb_xd_fragment',
      // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
      // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
      'conduitPage',
      // Generic error code from errors outside the security sandbox
      // You can delete this if using raven.js > 1.0, which ignores these automatically.
      'Script error.',
    ],
    denyUrls: [
      // Facebook flakiness
      /graph\.facebook\.com/i,
      // Facebook blocked
      /connect\.facebook\.net\/en_US\/all\.js/i,
      // Woopra flakiness
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      // Chrome extensions
      /extensions\//i,
      /^chrome:\/\//i,
      // Other plugins
      /127\.0\.0\.1:4001\/isrunning/i, // Cacaoweb
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
    ],
    replaysSessionSampleRate: 0,
    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: REACT_APP_ENV === 'production' ? 1.0 : 0,

    integrations: [
      new Replay({
        maskAllText: false,
        blockAllMedia: false,
      }),
    ],
  });
}

if (isNative) {
  document
    .querySelector('meta[name="viewport"]')
    ?.setAttribute(
      'content',
      'viewport-fit=cover, width=device-width, initial-scale=1, shrink-to-fit=no',
    );
  import('./initNativeApp').then((module) => {
    module.initApp();
  });
} else {
  import('./initApp').then((module) => {
    module.initApp();
  });
}
